import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONSTANTS} from 'src/app/core/utils/constants/constants';
import {environment} from 'src/environments/environment';
import {Inspection} from '../../interfaces/mia/inspection';
import {PscProcedureVersion} from '../../interfaces/mia/psc-procedure-version.model';

@Injectable({
  providedIn: 'root',
})
export class ProcedureQuestionsService {
  private apiUrl = `${environment.api.mia.url}`;

  constructor(private _http: HttpClient) {}

  getProcedureQuestions(
    procedureCode: string
  ): Observable<PscProcedureVersion> {
    return this._http.get<PscProcedureVersion>(
      `${this.apiUrl}${CONSTANTS.ROUTES.MIA.SIP.PROCEDURE_QUESTIONS}?procedureCode=${procedureCode}`
    );
  }

  postProcedureResults(inspection: Inspection): Observable<any> {
    return this._http.post(
      `${this.apiUrl}${CONSTANTS.ROUTES.MIA.SIP.PROCEDURE_RESULT}`,
      inspection,
      {responseType: 'text'}
    );
  }
}
