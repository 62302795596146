import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONSTANTS} from 'src/app/core/utils/constants/constants';
import {environment} from 'src/environments/environment';
import {IImage} from '../../interfaces/mia/image';
import {IImageResponse} from '../../interfaces/mia/image-response';

@Injectable({
  providedIn: 'root',
})
export class MiaImageService {
  baseUrl = `${environment.api.mia.url}${CONSTANTS.ENDPOINTS.MIA.IMAGES}`;

  constructor(private _http: HttpClient) {}

  getImagesById(
    inspectionId: number,
    isContractor: boolean
  ): Observable<IImage[]> {
    let contractorURL = isContractor ? `?isContractor=${isContractor}` : ``;
    return this._http.get<IImage[]>(
      `${this.baseUrl}/${inspectionId}${contractorURL}`
    );
  }

  getImagesByIdList(
    inspectionIds: string,
    isContractors: string
  ): Observable<IImage[]> {
    return this._http.get<IImage[]>(
      `${this.baseUrl}?inspectionIds=${inspectionIds}&isContractors=${isContractors}`
    );
  }

  postImages(images: IImage[]): Observable<IImageResponse[]> {
    return this._http.post<IImageResponse[]>(`${this.baseUrl}`, images);
  }

  deleteImages(imageIds: string[]): any {
    return this._http.put(`${this.baseUrl}`, imageIds, {responseType: 'text'});
  }
}
