export const ProcedureQuestionCodes = new Map<string, any>([
  // Pre Inspect
  ['PRI013', 'availablePocketsCount'],
  ['PRI017', 'ifNeutralsCut'],
  ['PRI003', 'ifFlaggersRequired'],
  ['PRI004', 'ifBackupCrewNeeded'],
  ['PRI002', 'itemsAffectingAccess'],
  ['PRI001', 'locationType'],
  ['PARK001', 'ifParkingRestrictionsExist'],
  ['PARK002', 'daysOfWeek'],
  ['PARK003', 'startTime'], // Start Time
  ['PARK004', 'endTime'], // End Time
  ['PARK002', 'daysOfWeek'],
  // Stray Voltage
  ['SVFND', 'ifStrayVoltageFound'],
  ['WHOFIXED', 'ifCrewConductRepairs'],
  ['SVORIGWRK', 'canOriginalWorkBePerformed'],
  ['CVR1', 'coverShapeAndSize'],
  ['CVR2', 'coverType'],
  ['CVR4', 'howCoverSecured'],
  // PSC UG Inspection
  ['IRHS03', 'ifIRScanPerformed'],
  ['PSCUG01', 'ifAccessRestrictionExists'],
  ['PSCUG03', 'itemsBlockingAccess'],
  ['PSCUG15', 'supervisorName'],
  ['PSCUG16', 'additionalComments'],
  ['PSCUG02', 'ifPermanentRepairsMade'],
  ['PSCUG04', 'defectsPermanentlyRepaired'],
  ['PSCUG05', 'ifStructuralDefectsExist'],
  ['PSCUG06', 'structuralDefectsNotRepaired'],
  ['PSCUG09', 'ifElectricalDefectsExist'],
  ['PSCUG10', 'electricalDefectsNotRepaired'],
  ['PSCUG11', 'ifTempElectricalRepairsMade'],
  ['PSCUG12', 'tempElectricalRepairsMade'],
  ['PSCUG14', 'tempElectricalRepairsComments'],
  // Sump Pump
  ['OM_YN', 'ifStructureHasOilMinder'],
  ['SP_YN', 'ifStructureHasSumpPump'],
  ['SP_MFG', 'sumpPumpManufacturer'],
  ['SPMAN', 'sumpPumpManufacturer'],
  ['SP_STATUS', 'sumpPumpStatus'],
  ['SPREP', 'sumpPumpStatus'],
  ['DRAINTYPE', 'drainType'],
]);
