import {Injectable} from '@angular/core';
import {CONSTANTS} from 'src/app/core/utils/constants/constants';
import {IStructureImage} from '../../interfaces/structure-image';
import {IUser} from '../../interfaces/user';
import {Inspection} from '../../interfaces/mia/inspection';
import {IImage} from '../../interfaces/mia/image';
import {ImageExifDataService} from '../image-exif-data/image-exif-data.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  distanceUnits = ['Feet', 'Miles', 'Kilometers'];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private imageExifDataService: ImageExifDataService) {}

  getDashboardRoute(user: IUser): string[] {
    let route: string[] = [];
    // Id	Classification
    // 1	Supervisor
    // 2	Mechanic
    // 3	General
    // 4	Search
    // 5	DE Civil = Search
    switch (user.portalClassificationId) {
      case 1: // 1	Supervisor
        route = [
          CONSTANTS.ROUTES.MAIN_DASHBOARD,
          CONSTANTS.ROUTES.SUPERVISOR_JOBS,
          CONSTANTS.ROUTES.WORK_REQUESTS,
        ];
        break;

      case 2: // 2	Mechanic
        route = [
          CONSTANTS.ROUTES.MAIN_DASHBOARD,
          CONSTANTS.ROUTES.MECHANIC_JOBS,
        ];
        break;

      case 3: // 3	General
        route = [CONSTANTS.ROUTES.MAIN_DASHBOARD, CONSTANTS.ROUTES.GENERAL];
        break;

      case 4: // 4	Search
        route = [CONSTANTS.ROUTES.SEARCH];
        break;

      case 5: // 5	DE Civil = Search
        route = [CONSTANTS.ROUTES.SEARCH];
        break;

      default:
        break;
    }

    return route;
  }

  getStructureImagesFromMedia(
    media: IStructureImage[]
    // setStatus: string
  ): IStructureImage[] {
    return media?.map((m) => {
      return {
        id: m?.id ?? null,
        imageType: m.imageType,
        comments: m.comments,
        // status: setStatus,
        fileName: m.data.fileName,
        path: m.path,
        createdOn: m.data.createdOn,
        tags: m.tags,
      };
    });
  }

  convertImagesToGalleryPhotos(images: any, workType = null) {
    return images
      .filter((image) => image.mediaType?.toLowerCase() !== 'video')
      .map((img) => {
        let path = '',
          rawPath = '',
          title = '',
          photoType = '',
          equipmentType = '',
          flushImageTags,
          comment,
          createdOn;
        if (workType?.toLowerCase() === 'era') {
          rawPath = path = img.imageUrl;
          title = img.photoName;
          createdOn = img.timestamp;
          photoType = img.photoTimeTaken;
          comment = img.comments;
          flushImageTags = img.flushImageTags;
        } else {
          path = img.base64String ?? img.fileUrl;
          rawPath = img.fileUrlRaw;
          title = img.fileName;
          createdOn = img.createdOn;
          photoType = img.photoType;
          equipmentType = img.equipmentType;
        }
        return {
          type: 'image',
          path: path,
          thermalPath: rawPath,
          data: {
            title: title,
            createdOn: createdOn,
            photoType: photoType,
            equipmentType: equipmentType,
            photoLocation: img.photoLocation,
            id: img.id,
            latitude: img.latitude,
            longitude: img.longitude,
            distance: img.distancefromAsset,
            avgTemperatureC: img.avgTemperatureC,
            avgTemperatureF: img.avgTemperatureF,
            minTemperatureC: img.minTemperatureC,
            minTemperatureF: img.minTemperatureF,
            maxTemperatureC: img.maxTemperatureC,
            maxTemperatureF: img.maxTemperatureF,
            comment: img.comment,
            flushImageTags: img.flushImageTags,
            section: img.section,
          },
        };
      });
  }

  convertCameraPhotosToImagesModel(
    base64String: string,
    inspection: Inspection,
    nextUniqueId,
    photoType = '',
    comment = ''
  ) {
    let img: any = {};
    img['base64String'] = base64String;
    img.fileName = `Img${++nextUniqueId}`;
    img.createdOn = new Date();
    img.inspectionId = inspection.id;
    img.contentSize = null;
    img.contentType = 'image/jpeg';
    img.createdBy = inspection.employeeEmail;
    img.id = self.crypto.randomUUID();
    img.photoType = photoType;
    img.photoLocation = '';
    img.comment = comment;
    img.latitude = inspection.structureLatitude;
    img.longitude = inspection.structureLongitude;
    return img;
  }

  calcCrow(lat1, lon1, lat2, lon2, unit: string): string {
    let R: number = 6371;
    if (unit == this.distanceUnits[0]) {
      R *= 3280.84; //feet
      unit = this.distanceUnits[0];
    } else if (unit == this.distanceUnits[1]) {
      R *= 0.62137; //miles
      unit = this.distanceUnits[1];
    } else {
      var unit = this.distanceUnits[2];
    }

    let dLat = this.toRad(lat2 - lat1);
    let dLon = this.toRad(lon2 - lon1);
    let lat1rad = this.toRad(lat1);
    let lat2rad = this.toRad(lat2);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1rad) *
        Math.cos(lat2rad);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = (R * c).toFixed(2);
    return d + ' ' + unit;
  }

  toRad(Value): any {
    return (Value * Math.PI) / 180;
  }

  convertUploadPhotosToImagesModel(
    photos: any[],
    inspection: Inspection,
    nextUniqueId,
    image,
    section = null
  ) {
    return Promise.all(
      photos.map(async (photo: File | any, index) => {
        const img: any = {};
        await this.imageExifDataService
          .getexifData(photo)
          .then((data) => {
            if (data) {
              if(data.exifdata) {
                img.make = data?.exifdata?.Make ?? 'make';
                img.model = data?.exifdata?.Model;
              }
              if (data?.gpsCords.latitude && data?.gpsCords.longitude) {
                img.latitude = data?.gpsCords?.latitude?.toString();
                img.longitude = data?.gpsCords?.longitude?.toString();
                img.distancefromAsset = this.calcCrow(
                  data?.gpsCords?.latitude,
                  data?.gpsCords?.longitude,
                  inspection.structureLatitude,
                  inspection.structureLongitude,
                  this.distanceUnits[0]
                );
              }
            }
          })
          .finally(() => {
            img.base64String = image.path;
            img.fileName = `Img${++nextUniqueId}`;
            img.createdOn = photo.fileInfo['lastModifiedDate'];
            img.inspectionId = inspection.id;
            img.contentSize = photo.fileInfo['size'].toString();
            img.contentType = photo.fileInfo['type'];
            img.createdBy = inspection.employeeEmail;
            img.id = self.crypto.randomUUID();
            img.photoType =
              inspection.workType === CONSTANTS.MIA.WORK_TYPE.UG_SIP
                ? 'Inspection'
                : inspection.workType === CONSTANTS.MIA.WORK_TYPE.UG_SIP
                ? 'Thermal'
                : '';
            img.photoType = image.photoType ?? '';
            img.photoLocation = '';
            img.comment = image.comment ?? '';
            img.isSaved = false;
            img.isUploaded = false;
          });
        return img;
      })
    );
  }

  arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    if(arr1?.length !== arr2?.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  }
}
