import {IImage} from 'src/app/shared/interfaces/mia/image';
import {Inspection} from 'src/app/shared/interfaces/mia/inspection';
import {IWorkRequest} from 'src/app/shared/interfaces/work-request';

export class SaveInspection {
  static readonly type = '[Ticket] Save Inspections';
  constructor(public inspection: Inspection, public saveToStoreOnly = false) {}
}
export class DeleteInspection {
  static readonly type = '[Ticket] Inspection';
  constructor(public inspectionId: number) {}
}

export class ResetInspectionAppState {
  static readonly type = '[App] Reset App State';
}

export class SaveImages {
  static readonly type = '[Images] Save Images';
  constructor(public images: IImage[], public saveToStorage: boolean = false) {}
}

export class GetImages {
  static readonly type = '[Images] Get Images';
  constructor(public inspectionId: number, public isContractor: boolean) {}
}

export class DeleteImages {
  static readonly type = '[Images] Delete Images';
  constructor(public imageIds: string[]) {}
}

export class SaveWRDetails {
  static readonly type = '[Ticket] Save WR';
  constructor(public wr: IWorkRequest) {}
}
