import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Observable, tap} from 'rxjs';
import {PscProcedureQuestion} from 'src/app/shared/interfaces/mia/psc-procedure-question.model';
import {ProcedureQuestionsService} from 'src/app/shared/services/procedure-questions/procedure-questions.service';
import {WorkRequestService} from 'src/app/shared/services/work-request/work-request.service';
import {ProcedureQuestionCodes} from '../../constants/procedure-question-codes';
import {FetchCrewCodes, GetProcedureQuestions} from './mia.actions';
import {IMiaState} from './mia.model';

@State<IMiaState>({
  name: 'MiaState',
  defaults: {
    procedureVersion: null,
    procedureAnswerOptions: [],
    crewCodes: [],
  },
})
@Injectable()
export class MiaState {
  constructor(
    private _procedureQuestionsService: ProcedureQuestionsService,
    private _workRequestService: WorkRequestService
  ) {}

  @Selector()
  static getCrewCodeOptions(state: IMiaState) {
    return state?.crewCodes;
  }

  @Selector()
  static getProcedureAnswerOptions(state: IMiaState) {
    return new Map(
      state?.procedureAnswerOptions?.map((o) => {
        return [o.fieldName, o.options];
      })
    );
  }

  // Get all the crew codes for the WR_Number
  @Action(FetchCrewCodes)
  FetchCrewCodes(
    ctx: StateContext<IMiaState>,
    action: FetchCrewCodes
  ): Observable<any[]> {
    return this._workRequestService.getCrewCodes(action.wrNumber).pipe(
      tap((crew_response: []) => {
        let state = ctx.getState();
        ctx.patchState({
          ...state,
          crewCodes: crew_response,
        });
      })
    );
  }

  @Action(GetProcedureQuestions)
  GetProcedureQuestions(
    ctx: StateContext<IMiaState>,
    action: GetProcedureQuestions
  ) {
    return this._procedureQuestionsService
      .getProcedureQuestions(action.procedureCode)
      .pipe(
        tap((response: any) => {
          let answerOptions = [];
          response.pscProcedureQuestions.forEach((r: PscProcedureQuestion) => {
            answerOptions.push({
              fieldName: ProcedureQuestionCodes.get(r.stepCode),
              options: r.answerOptions.map((a) => a.resultDescription),
            });
          });
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            procedureVersion: response.procedureVersion,
            procedureAnswerOptions: answerOptions,
          });
        })
      );
  }
}
