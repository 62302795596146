import {Injectable} from '@angular/core';
import {ImageGpsCords} from 'src/app/shared/interfaces/mia/image-gps-cords';

const TiffTags = {
  0x0100: 'ImageWidth',
  0x0101: 'ImageHeight',
  0x8769: 'ExifIFDPointer',
  0x8825: 'GPSInfoIFDPointer',
  0xa005: 'InteroperabilityIFDPointer',
  0x0102: 'BitsPerSample',
  0x0103: 'Compression',
  0x0106: 'PhotometricInterpretation',
  0x0112: 'Orientation',
  0x0115: 'SamplesPerPixel',
  0x011c: 'PlanarConfiguration',
  0x0212: 'YCbCrSubSampling',
  0x0213: 'YCbCrPositioning',
  0x011a: 'XResolution',
  0x011b: 'YResolution',
  0x0128: 'ResolutionUnit',
  0x0111: 'StripOffsets',
  0x0116: 'RowsPerStrip',
  0x0117: 'StripByteCounts',
  0x0201: 'JPEGInterchangeFormat',
  0x0202: 'JPEGInterchangeFormatLength',
  0x012d: 'TransferFunction',
  0x013e: 'WhitePoint',
  0x013f: 'PrimaryChromaticities',
  0x0211: 'YCbCrCoefficients',
  0x0214: 'ReferenceBlackWhite',
  0x0132: 'DateTime',
  0x010e: 'ImageDescription',
  0x010f: 'Make',
  0x0110: 'Model',
  0x0131: 'Software',
  0x013b: 'Artist',
  0x8298: 'Copyright',
};

const GPSTags = {
  0x0000: 'GPSVersionID',
  0x0001: 'GPSLatitudeRef',
  0x0002: 'GPSLatitude',
  0x0003: 'GPSLongitudeRef',
  0x0004: 'GPSLongitude',
  0x0005: 'GPSAltitudeRef',
  0x0006: 'GPSAltitude',
  0x0007: 'GPSTimeStamp',
  0x0008: 'GPSSatellites',
  0x0009: 'GPSStatus',
  0x000a: 'GPSMeasureMode',
  0x000b: 'GPSDOP',
  0x000c: 'GPSSpeedRef',
  0x000d: 'GPSSpeed',
  0x000e: 'GPSTrackRef',
  0x000f: 'GPSTrack',
  0x0010: 'GPSImgDirectionRef',
  0x0011: 'GPSImgDirection',
  0x0012: 'GPSMapDatum',
  0x0013: 'GPSDestLatitudeRef',
  0x0014: 'GPSDestLatitude',
  0x0015: 'GPSDestLongitudeRef',
  0x0016: 'GPSDestLongitude',
  0x0017: 'GPSDestBearingRef',
  0x0018: 'GPSDestBearing',
  0x0019: 'GPSDestDistanceRef',
  0x001a: 'GPSDestDistance',
  0x001b: 'GPSProcessingMethod',
  0x001c: 'GPSAreaInformation',
  0x001d: 'GPSDateStamp',
  0x001e: 'GPSDifferential',
};
@Injectable({
  providedIn: 'root',
})
export class ImageExifDataService {
  constructor() {}

  getexifData(image: any): Promise<any> {
    var gpsCords;
    return new Promise((resolve) => {
      if (image) {
        var img = image.fileInfo;

        if (
          self.FileReader &&
          (img instanceof self.Blob || img instanceof self.File)
        ) {
          var fileReader = new FileReader();
          fileReader.onload = (e) => {
            {
              var imagedata = this.handleBinaryFile(e.target.result, img);
              if (imagedata) gpsCords = this.convertToGpsDD(imagedata);
              resolve({gpsCords: gpsCords, exifdata: imagedata.exifdata});
            }
          };
          fileReader.readAsArrayBuffer(img);
        }
      }
    });
  }

  private convertToGpsDD(image: any): any {
    if (image['GPSLatitude'] && ['GPSLongitude']) {
      var latSign = 1,
        longSign = 1;
      var latRef = image['GPSLatitudeRef'];
      var longRef = image['GPSLongitudeRef'];
      var latdegree = image['GPSLatitude'][0];
      var latminute = image['GPSLatitude'][1];
      var latsecond = image['GPSLatitude'][2];

      var longdegree = image['GPSLongitude'][0];
      var longminute = image['GPSLongitude'][1];
      var longsecond = image['GPSLongitude'][2];

      if (latRef === 'S') {
        latSign = -1;
      }
      if (longRef === 'W') {
        longSign = -1;
      }
      var gpsLat = latSign * (latdegree + latminute / 60 + latsecond / 3600);
      var gpsLong =
        longSign * (longdegree + longminute / 60 + longsecond / 3600);

      let gpsCord: ImageGpsCords = {
        latitude: gpsLat,
        longitude: gpsLong,
      };
      return gpsCord;
    }
    return [];
  }

  private readTags(file, tiffStart, dirStart, strings, bigEnd): any {
    var entries = file.getUint16(dirStart, !bigEnd),
      tags = {},
      entryOffset,
      tag,
      i;

    for (i = 0; i < entries; i++) {
      entryOffset = dirStart + i * 12 + 2;
      tag = strings[file.getUint16(entryOffset, !bigEnd)];
      tags[tag] = this.readTagValue(
        file,
        entryOffset,
        tiffStart,
        dirStart,
        bigEnd
      );
    }
    return tags;
  }

  private readTagValue(file, entryOffset, tiffStart, dirStart, bigEnd): any {
    var type = file.getUint16(entryOffset + 2, !bigEnd),
      numValues = file.getUint32(entryOffset + 4, !bigEnd),
      valueOffset = file.getUint32(entryOffset + 8, !bigEnd) + tiffStart,
      offset,
      vals,
      val,
      n,
      numerator,
      denominator;

    switch (type) {
      case 1: // byte, 8-bit unsigned int
      case 7: // undefined, 8-bit byte, value depending on field
        if (numValues == 1) {
          return file.getUint8(entryOffset + 8, !bigEnd);
        } else {
          offset = numValues > 4 ? valueOffset : entryOffset + 8;
          vals = [];
          for (n = 0; n < numValues; n++) {
            vals[n] = file.getUint8(offset + n);
          }
          return vals;
        }

      case 2: // ascii, 8-bit byte
        offset = numValues > 4 ? valueOffset : entryOffset + 8;
        return this.getStringFromDB(file, offset, numValues - 1);

      case 3: // short, 16 bit int
        if (numValues == 1) {
          return file.getUint16(entryOffset + 8, !bigEnd);
        } else {
          offset = numValues > 2 ? valueOffset : entryOffset + 8;
          vals = [];
          for (n = 0; n < numValues; n++) {
            vals[n] = file.getUint16(offset + 2 * n, !bigEnd);
          }
          return vals;
        }

      case 4: // long, 32 bit int
        if (numValues == 1) {
          return file.getUint32(entryOffset + 8, !bigEnd);
        } else {
          vals = [];
          for (n = 0; n < numValues; n++) {
            vals[n] = file.getUint32(valueOffset + 4 * n, !bigEnd);
          }
          return vals;
        }

      case 5: // rational = two long values, first is numerator, second is denominator
        if (numValues == 1) {
          numerator = file.getUint32(valueOffset, !bigEnd);
          denominator = file.getUint32(valueOffset + 4, !bigEnd);
          val = new Number(numerator / denominator);
          val.numerator = numerator;
          val.denominator = denominator;
          return val;
        } else {
          vals = [];
          for (n = 0; n < numValues; n++) {
            numerator = file.getUint32(valueOffset + 8 * n, !bigEnd);
            denominator = file.getUint32(valueOffset + 4 + 8 * n, !bigEnd);
            vals[n] = new Number(numerator / denominator);
            vals[n].numerator = numerator;
            vals[n].denominator = denominator;
          }
          return vals;
        }

      case 9: // slong, 32 bit signed int
        if (numValues == 1) {
          return file.getInt32(entryOffset + 8, !bigEnd);
        } else {
          vals = [];
          for (n = 0; n < numValues; n++) {
            vals[n] = file.getInt32(valueOffset + 4 * n, !bigEnd);
          }
          return vals;
        }

      case 10: // signed rational, two slongs, first is numerator, second is denominator
        if (numValues == 1) {
          return (
            file.getInt32(valueOffset, !bigEnd) /
            file.getInt32(valueOffset + 4, !bigEnd)
          );
        } else {
          vals = [];
          for (n = 0; n < numValues; n++) {
            vals[n] =
              file.getInt32(valueOffset + 8 * n, !bigEnd) /
              file.getInt32(valueOffset + 4 + 8 * n, !bigEnd);
          }
          return vals;
        }
    }
  }

  private getStringFromDB(buffer: any, start: number, length: number): string {
    var outstr = '';
    for (let n = start; n < start + length; n++) {
      outstr += String.fromCharCode(buffer.getUint8(n));
    }
    return outstr;
  }

  private readEXIFData(file, start): boolean {
    if (this.getStringFromDB(file, start, 4) != 'Exif') {
      //"Not valid EXIF data! " + this.getStringFromDB(file, start, 4));
      return false;
    }

    var bigEnd,
      tags,
      tag,
      exifData,
      gpsData,
      tiffOffset = start + 6;

    // test for TIFF validity and endianness
    if (file.getUint16(tiffOffset) == 0x4949) {
      bigEnd = false;
    } else if (file.getUint16(tiffOffset) == 0x4d4d) {
      bigEnd = true;
    } else {
      //"Not valid TIFF data! (no 0x4949 or 0x4D4D)"
      return false;
    }

    if (file.getUint16(tiffOffset + 2, !bigEnd) != 0x002a) {
      //"Not valid TIFF data! (no 0x002A)"
      return false;
    }

    var firstIFDOffset = file.getUint32(tiffOffset + 4, !bigEnd);

    if (firstIFDOffset < 0x00000008) {
      //"Not valid TIFF data! (First offset less than 8)", file.getUint32(tiffOffset+4, !bigEnd)
      return false;
    }

    tags = this.readTags(
      file,
      tiffOffset,
      tiffOffset + firstIFDOffset,
      TiffTags,
      bigEnd
    );

    if (tags.GPSInfoIFDPointer) {
      gpsData = this.readTags(
        file,
        tiffOffset,
        tiffOffset + tags.GPSInfoIFDPointer,
        GPSTags,
        bigEnd
      );
      for (tag in gpsData) {
        switch (tag) {
          case 'GPSVersionID':
            gpsData[tag] =
              gpsData[tag][0] +
              '.' +
              gpsData[tag][1] +
              '.' +
              gpsData[tag][2] +
              '.' +
              gpsData[tag][3];
            break;
        }
        tags[tag] = gpsData[tag];
      }
    }

    return tags;
  }

  private findEXIFinJPEG(file): any {
    var dataView = new DataView(file);

    if (dataView.getUint8(0) != 0xff || dataView.getUint8(1) != 0xd8) {
      //"Not a valid JPEG"
      return false; // not a valid jpeg
    }

    var offset = 2,
      length = file.byteLength,
      marker;

    while (offset < length) {
      if (dataView.getUint8(offset) != 0xff) {
        //"Not a valid marker at offset " + offset + ", found: " + dataView.getUint8(offset)
        return false; // not a valid marker, something is wrong
      }

      marker = dataView.getUint8(offset + 1);

      // we could implement handling for other markers here,
      // but we're only looking for 0xFFE1 for EXIF data

      if (marker == 225) {
        //"Found 0xFFE1 marker"
        var temp = this.readEXIFData(dataView, offset + 4);
        return temp;
        // offset += 2 + file.getShortAt(offset+2, true);
      } else {
        offset += 2 + dataView.getUint16(offset + 2);
      }
    }
  }

  private handleBinaryFile(binFile: any, img: any) {
    var data = this.findEXIFinJPEG(binFile);
    img.exifdata = data|| {};
    return {...data, exifdata: img.exifdata};
  }
}
