import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {CONSTANTS} from 'src/app/core/utils/constants/constants';
import {environment} from 'src/environments/environment';
import {IWorkRequest} from '../../interfaces/work-request';

@Injectable({
  providedIn: 'root',
})
export class WorkRequestService {
  baseWrUrl = `${environment.api.mia.url}${CONSTANTS.ENDPOINTS.MIA.WORK_REQUESTS}`;

  constructor(private http: HttpClient) {}

  getWorkRequestDetails(wrNumber: string): Observable<IWorkRequest> {
    return this.http.get<IWorkRequest>(`${this.baseWrUrl}?wrnumber=${wrNumber}`);
  }

  getCrewCodes(wrNumber: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api.mia.url}${CONSTANTS.ENDPOINTS.MIA.CREW_CODE}?wrNumber=${wrNumber}`
    );
  }
}
